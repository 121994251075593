import axios from "axios"
import {
  apiEndpointProd,
  frontEndUrl,
  tokenPrefix,
  apiKey,
  apiEndpointDev,
} from "project.config"

export const FRONT_END_URL = frontEndUrl
export const API_URL_PROD = apiEndpointProd
export const API_URL_DEV = apiEndpointDev

export const TOKEN_PREFIX = tokenPrefix

export const axiosApi = axios.create({
  baseURL: API_URL_PROD,
  withCredentials: true,
})

axiosApi.interceptors.request.use(
  function (config) {
    config.headers["ApiKey"] = apiKey
    // const token = localStorage.getItem(TOKEN_PREFIX)

    // if (token) {
    //   config.headers["Authorization"] =
    //     "Bearer " + localStorage.getItem(TOKEN_PREFIX),
    //     config.headers["ApiKey"] = apiKey
    // }

    return config
  },
  function (error) {}
)

axiosApi.interceptors.response.use(
  response => {
    return response
  },
  error => {
    // window.history.replaceState({}, "", "/500")
    return error.response
  }
)

export async function get(url, config = {}) {
  return await axiosApi.get(url, { ...config }).then(response => response.data)
}

export async function post(url, data, config = {}) {
  return axiosApi
    .post(url, { ...data }, { ...config })
    .then(response => response.data)
}

export async function ApiPut(url, data, config = {}) {
  return axiosApi
    .put(url, { ...data }, { ...config })
    .then(response => response.data)
}

export async function patch(url, data, config = {}) {
  return axiosApi
    .patch(url, { ...data }, { ...config })
    .then(response => response.data)
}

export async function del(url, config = {}) {
  return await axiosApi
    .delete(url, { ...config })
    .then(response => response.data)
}
