import { TOKEN_PREFIX } from "helpers/api_methods"
import React from "react"
import { useDispatch, useSelector } from "react-redux"
import { Link, useHistory, useLocation } from "react-router-dom"
import PropTypes from "prop-types"
import "boxicons"
import { showLoginPopupAction } from "store/actions"

import home from "assets/imgs/icons/home.png"
import about from "assets/imgs/icons/about.png"
import cart from "assets/imgs/icons/cart.png"
import account from "assets/imgs/icons/account.png"
import product from "assets/imgs/icons/product.png"

const MobileNav = ({ navItems }) => {
  const location = useLocation()
  const dispatch = useDispatch()
  const history = useHistory()

  const token = localStorage.getItem(TOKEN_PREFIX)

  const { cartItems, localCart, isLoginPopup, cartNoti, mobileNav } =
    useSelector(state => ({
      cartItems: state.Cart.cartItems,
      localCart: state.Cart.localCart,
      isLoginPopup: state.Layout.isLoginPopup,
      cartNoti: state.Layout.cartNoti,
      mobileNav: state.Layout.mobileNav,
    }))

  const handleCartItem = () => {
    if (cartItems?.length >= 1) {
      return cartItems
    } else if (localCart?.length >= 1) {
      return localCart?.filter(i => i?.quantity !== 0)
    } else {
      return []
    }
  }

  const handleAuth = () => {
    if (token) {
      history.push("/user-account")
    } else {
      dispatch(showLoginPopupAction())
    }
  }

  return (
    <>
      <div
        className={
          "MobileNav " + `${mobileNav === false ? "hideToBottom" : ""}`
        }
        // style={{
        //   boxShadow:
        //     isLoginPopup || cartNoti
        //       ? ""
        //       : "rgb(149 157 165 / 20%) 0px -5px 24px",
        // }}
      >
        {navItems?.setup?.bottomNavigationBarValues?.map((item, key) => (
          <Link to={item?.link} className="text-gray mobile_nav_item" key={key}>
            {/* <img className="mb-1" src={home} alt="" /> */}
            {/* <box-icon name={item?.icon} color="white"></box-icon> */}
            {item && item?.iconSVG[0] && (
              <img
                src={item?.iconSVG[0]?.url}
                alt=""
                width={"25px"}
                height={"25px"}
              />
            )}
            <span>{item?.title}</span>
          </Link>
        ))}

        {/* <Link
            to={{
              pathname: "/products",
              query: {
                prod: "all",
              },
            }}
            className="text-gray mobile_nav_item"
          >
            <img className="mb-1" src={product} alt="" />

            <span>Products</span>
          </Link>

          <Link
            to={"#"}
            onClick={handleAuth}
            className="text-gray mobile_nav_item position-relative"
          >
            <img className="mb-1" src={account} height={"20px"} alt="" />

            <span>Account</span>
          </Link>
          <Link
            to={"/cart"}
            className="text-gray mobile_nav_item position-relative"
          >
            <img className="mb-1" src={cart} alt="" height={"20px"} />

            <span>Cart</span>
            {handleCartItem()?.length >= 1 && (
              <span
                className="pro-count text-white navIcon2 bg-darkGreen px-1"
                style={{ borderRadius: "50%" }}
              >
                {handleCartItem()?.length}
              </span>
            )}
          </Link>
          <Link to={"/about"} className="text-gray mobile_nav_item">
            <img className="mb-1" src={about} alt="" />
            <span>About</span>
          </Link> */}
      </div>
    </>
  )
}

export default MobileNav

MobileNav.propTypes = {
  navItems: PropTypes.any,
}
