import React, { Suspense, useEffect, useState } from "react"
import PropTypes from "prop-types"
import { connect, useDispatch, useSelector } from "react-redux"
import {
  Switch,
  BrowserRouter as Router,
  Route,
  Redirect,
  useLocation,
} from "react-router-dom"

// Import Routes all
import { publicRoutes } from "./routes"

// Import all middleware
import Authmiddleware from "./routes/route"

// layouts Format
import NonAuthLayout from "./components/NonAuthLayout"
import Preloader from "components/elements/Preloader"
import ReactGA from "react-ga"

import "react-perfect-scrollbar/dist/css/styles.css"
import "react-responsive-modal/styles.css"
import "swiper/swiper-bundle.min.css"
import "swiper/swiper.min.css"
import "./assets/css/main.css"
import "./assets/css/custom.css"
import "./assets/sass/custom.scss"

import { Notification } from "components/Common/Notification"

import { getPagesHome, getPages } from "store/actions"

import CartBottomNotification from "components/elements/cartBottomNotification"
import UesWindowScrollTop from "components/hooks/uesWindowScrollTop"
import MobileNav from "components/layout/MobileNav"
import storage from "util/localStorage"
import BannerLoader from "components/Common/loaders/BannerLoader"

const App = props => {
  const dispatch = useDispatch()
  const location = useLocation()

  const [loading, setLoading] = useState(false)
  const TRACKING_ID = "G-15P77D02FM"
  ReactGA.initialize(TRACKING_ID)

  const { cartNoti, favorites, pages } = useSelector(state => ({
    cartNoti: state.Layout.cartNoti,
    favorites: state.Cart.favorites,
    pages: state.LayoutPages.pages,
  }))

  useEffect(() => {
    setLoading(true)
    setTimeout(() => {
      setLoading(false)
    }, 2500)
  }, [])

  // useEffect(() => {
  //   const pre_location = localStorage.getItem("pre_location")
  //   const lat = parseFloat(pre_location?.split(",")[0])
  //   const lng = parseFloat(pre_location?.split(",")[1])

  //   if (pre_location) {
  //     dispatch(getUserLocation([lat, lng]))
  //   } else {
  //     setTimeout(() => {
  //       dispatch(showLocationPopupAction())
  //     }, 2000)
  //     navigator.geolocation.getCurrentPosition(
  //       function (position) {
  //         dispatch(
  //           getUserLocation([
  //             position.coords.latitude,
  //             position.coords.longitude,
  //           ])
  //         )
  //       },
  //       function () {
  //         dispatch(getDefaultStore())
  //       }
  //     )
  //   }
  // }, [dispatch])

  // useEffect(() => {
  //   if (token) {
  //     dispatch(getUser())
  //   }
  // }, [token])

  // useEffect(() => {
  //   dispatch(setLanguageAction("en"))
  // }, [])

  // useEffect(() => {
  // }, [dispatch])

  // useEffect(() => {
  //   if (!token) {
  //     dispatch(getDefaultStore())
  //   }
  // }, [token])

  // useEffect(() => {
  //   if (userLocation?.length === 2) {
  //     if (token) {
  //       dispatch(getNearestStore({ coordinates: userLocation }))
  //     } else {
  //       dispatch(getNearestStoreNoUser({ coordinates: userLocation }))
  //     }

  //     dispatch(getUserPlaces(userLocation[0], userLocation[1]))
  //   }
  // }, [userLocation, dispatch, token])

  // useEffect(() => {
  //   if (isLoginPopup === true) {
  //     document.body.style.overflow = "hidden"
  //   } else {
  //     document.body.style.overflow = "auto"
  //   }
  // }, [isLoginPopup])

  useEffect(() => {
    dispatch(getPages())
  }, [dispatch])

  useEffect(() => {
    const favIds = favorites?.favorites?.map(fav => fav.product?._id)
    if (favIds?.length >= 1) {
      storage.set("user_favourite", favIds)
    }
  })

  useEffect(() => {
    ReactGA.pageview(location.pathname + location.search)
  }, [])

  useEffect(() => {
    dispatch(getPagesHome("home"))
  }, [])

  const theme = pages?.setup?.theme
  const colors = pages?.setup

  // const handlePublicRoutes = () => {
  //   if (
  //     ?.setup?.isCustomHomePage &&
  //     homePage?.sections &&
  //     homePage?.sections[0]?._id
  //   ) {
  //     const modifiedRoute = publicRoutes?.map(route =>
  //       route?.path === "/" ? { ...route, component: LandingPage } : route
  //     )
  //     return modifiedRoute
  //   } else {
  //     return publicRoutes
  //   }
  // }

  return (
    <React.Fragment>
      <style>
        {`
       :root {
        --primary-colors : ${
          theme?.primaryColor ? theme?.primaryColor : "#22b3be"
        };
        --secondary-color: ${
          theme?.secondaryColor ? theme?.secondaryColor : "#ed475f"
        };
        --footer-background: ${
          colors?.footerBackgroundColor
            ? colors?.footerBackgroundColor
            : "#ed475f"
        };
        --footer-foreground: ${
          colors?.footerForegroundColor
            ? colors?.footerForegroundColor
            : "#ed475f"
        };
        --header-background: ${
          colors?.headerBackgroundColor
            ? colors?.footerBackgroundColor
            : "#ed475f"
        };
        --header-foreground: ${
          colors?.headerForegroundColor ? colors?.headerForegroundColor : ""
        };
      }`}
      </style>

      <Notification />
      {/* {isLoginPopup && <LoginPopup />} */}
      {/* {isLocationPopup && <LocationPopUp />} */}
      {/* <AreaListPopup /> */}

      {loading ? (
        <Preloader />
      ) : (
        <Suspense
          fallback={
            <div>
              <BannerLoader />
              <BannerLoader />
              <BannerLoader />
            </div>
          }
        >
          <Router>
            {pages?.setup?.isBottomNavigationBarNeeded && (
              <MobileNav navItems={pages} />
            )}
            <UesWindowScrollTop />
            {cartNoti && <CartBottomNotification />}

            <Switch>
              {publicRoutes?.map((route, idx) => (
                <Authmiddleware
                  path={route.path}
                  layout={NonAuthLayout}
                  component={route.component}
                  key={idx}
                  isAuthProtected={false}
                  exact
                />
              ))}
              {/* {AuthProtectedRoute?.map((route, idx) => (
                <Authmiddleware
                  path={route.path}
                  layout={NonAuthLayout}
                  component={route.component}
                  key={idx}
                  isAuthProtected={true}
                  exact
                />
              ))} */}
              <Route render={() => <Redirect to={{ pathname: "/404" }} />} />,
            </Switch>
          </Router>
        </Suspense>
      )}
    </React.Fragment>
  )
}

App.propTypes = {
  layout: PropTypes.any,
}

const mapStateToProps = state => {
  return {
    layout: state.Layout,
  }
}

export default connect(mapStateToProps, null)(App)
