import {
  GET_LAYOUTPAGES,
  GET_LAYOUTPAGES_SUCCESS,
  GET_LAYOUTPAGES_FAIL,
  GET_PAGES_HOME,
  GET_PAGES_HOME_FAIL,
  GET_PAGES_HOME_SUCCESS,
  GET_PAGES,
  GET_PAGES_SUCCESS,
  GET_PAGES_FAIL,
  GET_SINGLE_PAGE,
  GET_SINGLE_PAGE_SUCCESS,
  GET_SINGLE_PAGE_FAIL,
} from "./actionTypes"

const INIT_STATE = {
  layoutPages: {},
  error: {},
  loading: false,
  homePage: {},
  pages: {},
}

const LayoutPages = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_LAYOUTPAGES:
    case GET_PAGES_HOME:
    case GET_PAGES:
    case GET_SINGLE_PAGE:
      return {
        ...state,
        loading: true,
      }
    case GET_PAGES_SUCCESS:
      return {
        ...state,
        pages: action.payload,
        loading: false,
      }
    case GET_LAYOUTPAGES_SUCCESS:
      return {
        ...state,
        layoutPages: action.payload,
        loading: false,
      }
    case GET_SINGLE_PAGE_SUCCESS:
      return {
        ...state,
        layoutPages: action.payload,
        loading: false,
      }
    case GET_LAYOUTPAGES_FAIL:
    case GET_PAGES_HOME_FAIL:
    case GET_PAGES_FAIL:
    case GET_SINGLE_PAGE_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }

    case GET_PAGES_HOME_SUCCESS:
      return {
        ...state,
        homePage: action.payload,
        loading: false,
      }
    default:
      return state
  }
}

export default LayoutPages
