module.exports = {
  primaryColor: "#22b3be",
  secondaryColor: "ed475f",
  companyName: "LOHA Home Studio",
  shortName: "LOHA",
  apiKey: "6d481f2e-74b3-4f0f-a7c5-8f8fd12e5191",
  siteMetadata: {
    websiteTitle: "LOHA",
    description: "LOHA Home Studio",
    keywords: "",
    faviconUrl: "",
    logoUrl: "",
    secondaryLogo: "",
  },
  apiEndpointDev: "http://192.168.1.37:4000",
  apiEndpointProd: "https://api.groweb.osperb.com",
  // apiEndpointProd: "https://api.chocochi.com",
  frontEndUrl: "https://groweb.osperb.com",
  tokenPrefix: "groweb_token",
}
