import { lazy } from "react"

// Dashboard
const LandingPage = lazy(() => import("pages/LandingPages"))
const AllPages = lazy(() => import("pages/LandingPages/AllPages"))
const SearchPage = lazy(() => import("components/ecommerce/search/index"))
const StaticPage = lazy(() => import("pages/static/StaticPage"))
const CreateLead = lazy(() => import("pages/Lead/create"))
const Pages404 = lazy(() => import("pages/404/pages-404"))
const Pages500 = lazy(() => import("pages/404/pages-500"))
// const SingleCollection = lazy(() =>
//   import("pages/custom/collection/SingleCollection")
// )
// const Pages503 = lazy(() => import("pages/404/paymentComplete"))
// const CheckoutLogin = lazy(() => import("pages/checkout/checkoutLogin"))
// const AllColllection = lazy(() => import("pages/custom/collection/collections"))
// const OrderDetails = lazy(() => import("pages/user/order/orderDetails"))
// const AllCategoryList = lazy(() => import("pages/category"))
// const OrderSuccess = lazy(() => import("pages/checkout/OrderSuccess"))
// const Checkout = lazy(() => import("pages/checkout/checkout"))
// const About = lazy(() => import("pages/static/page-about"))
// const WishlistModal = lazy(() => import("pages/shop/shop-wishlist"))
// const Account = lazy(() => import("pages/user/page-account"))
// const ProductsList = lazy(() => import("pages/shop/shop-list-left"))
// const Cart = lazy(() => import("pages/shop/shop-cart"))
// const ProductSingleView = lazy(() => import("pages/product"))
// const Home = lazy(() => import("pages/Home"))
// const Pages502 = lazy(() => import("pages/404/paymentFail"))
const AllBlogList = lazy(() => import("pages/Blog"))
const SingleBlog = lazy(() => import("pages/Blog/SingleBlogPage"))
// const Test = lazy(() => import("pages/animation"))

const publicRoutes = [
  //defualt routes
  { path: "/404", component: Pages404 },
  { path: "/500", component: Pages500 },
  { path: "/", component: LandingPage },
  //static routes
  { path: "/contact", component: CreateLead },
  { path: "/privacy-policy", component: StaticPage },
  { path: "/return-policy", component: StaticPage },
  { path: "/terms", component: StaticPage },
  { path: "/search", component: SearchPage },
  { path: "/pages", component: AllPages },
  { path: "/:name", component: LandingPage },
  //blog
  { path: "/blog/:name", component: SingleBlog },
  { path: "/blogs", component: AllBlogList },

  //main routes
  // { path: "/custom/:type", component: CustomGrid },
  // { path: "/custom/:type/:name", component: CustomGrid },

  // { path: "/products/:name", component: ProductSingleView },
  // { path: "/categroies", component: AllCategoryList },
  // { path: "/products", component: ProductsList },
  // { path: "/collections", component: AllColllection },
  // { path: "/collections/:name", component: SingleCollection }, //pending

  // { path: "/cart", component: Cart },
  // { path: "/wishlist", component: WishlistModal },
  // { path: "/quick-purchase", component: shopQuickPurchase },

  //pages

  // { path: "/checkout/login", component: CheckoutLogin },

  // { path: "/animation", component: Test },
]

// const AuthProtectedRoute = [
//   { path: "/user-account", component: Account },
//   { path: "/user/order/:id", component: OrderDetails },
//   { path: "/checkout", component: Checkout },
//   { path: "/order-complete/:id", component: Pages503 },

//   { path: "/order-success", component: OrderSuccess },
//   { path: "/online-order-fail", component: Pages502 },
// ]

export { publicRoutes }
