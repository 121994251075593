import {
  GET_LEADS,
  GET_LEADS_SUCCESS,
  GET_LEADS_FAIL,
  GET_LEAD_DETAILS,
  GET_LEAD_DETAILS_SUCCESS,
  GET_LEAD_DETAILS_FAIL,
  CREATE_LEAD,
  CREATE_LEAD_SUCCESS,
  CREATE_LEAD_FAIL,
  UPDATE_LEAD,
  UPDATE_LEAD_SUCCESS,
  UPDATE_LEAD_FAIL,
  DELETE_LEAD_SUCCESS,
  DELETE_LEAD_FAIL,
  DELETE_LEAD,
} from "./actionTypes"

// Lead - This line cannot be edited or removed
export const getLeads = (page, sort, limit, searchText) => ({
  type: GET_LEADS,
  payload: { page, sort, limit, searchText },
})

export const getLeadsSuccess = leads => ({
  type: GET_LEADS_SUCCESS,
  payload: leads,
})

export const getLeadsFail = error => ({
  type: GET_LEADS_FAIL,
  payload: error,
})

export const getLeadDetails = leadId => ({
  type: GET_LEAD_DETAILS,
  payload: leadId,
})

export const getLeadDetailsSuccess = leadDetails => ({
  type: GET_LEAD_DETAILS_SUCCESS,
  payload: leadDetails,
})

export const getLeadDetailsFail = error => ({
  type: GET_LEAD_DETAILS_FAIL,
  payload: error,
})

export const createLead = (lead, dispatch) => ({
  type: CREATE_LEAD,
  payload: { lead, dispatch },
})

export const createLeadSuccess = lead => ({
  type: CREATE_LEAD_SUCCESS,
  payload: lead,
})

export const createLeadFail = error => ({
  type: CREATE_LEAD_FAIL,
  payload: error,
})

export const getLeadDetail = leadId => ({
  type: GET_LEAD_DETAILS,
  payload: leadId,
})

export const updateLead = (lead, leadId, history) => ({
  type: UPDATE_LEAD,
  payload: { lead, leadId, history },
})

export const updateLeadSuccess = lead => ({
  type: UPDATE_LEAD_SUCCESS,
  payload: lead,
})

export const updateLeadFail = error => ({
  type: UPDATE_LEAD_FAIL,
  payload: error,
})

export const deleteLead = (leadId, history) => ({
  type: DELETE_LEAD,
  payload: { leadId, history },
})

export const deleteLeadSuccess = lead => ({
  type: DELETE_LEAD_SUCCESS,
  payload: lead,
})

export const deleteLeadFail = error => ({
  type: DELETE_LEAD_FAIL,
  payload: error,
})
