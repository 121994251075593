import {
  GET_COLLECTIONS,
  GET_COLLECTIONS_FAIL,
  GET_COLLECTIONS_SUCCESS,
  GET_LAYOUTGROUPS,
  GET_LAYOUTGROUPS_FAIL,
  GET_LAYOUTGROUPS_SUCCESS,
  GET_SINGLE_COLLECTION,
  GET_SINGLE_COLLECTION_FAIL,
  GET_SINGLE_COLLECTION_SUCCESS,
} from "./actionTypes"

export const getLayoutGroups = (page, platform, limit, store) => ({
  type: GET_LAYOUTGROUPS,
  payload: { page, platform, limit, store },
})

export const getLayoutGroupsSuccess = groupData => ({
  type: GET_LAYOUTGROUPS_SUCCESS,
  payload: groupData,
})
export const getLayoutGroupsFail = error => ({
  type: GET_LAYOUTGROUPS_FAIL,
  payload: error,
})

export const getCollections = () => ({
  type: GET_COLLECTIONS,
})

export const getCollectionsSuccess = collectionData => ({
  type: GET_COLLECTIONS_SUCCESS,
  payload: collectionData,
})
export const getCollectionsFail = error => ({
  type: GET_COLLECTIONS_FAIL,
  payload: error,
})

export const getSingleCollection = collectionName => ({
  type: GET_SINGLE_COLLECTION,
  payload: { collectionName },
})

export const getSingleCollectionSuccess = collectionData => ({
  type: GET_SINGLE_COLLECTION_SUCCESS,
  payload: collectionData,
})
export const getSingleCollectionFail = error => ({
  type: GET_SINGLE_COLLECTION_FAIL,
  payload: error,
})
