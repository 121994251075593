import { call, put, takeEvery } from "redux-saga/effects"

import {
  GET_LEADS,
  GET_LEAD_DETAILS,
  CREATE_LEAD,
  UPDATE_LEAD,
  DELETE_LEAD,
} from "./actionTypes"
import {
  getLeadsFail,
  getLeadsSuccess,
  getLeadDetailsSuccess,
  getLeadDetailsFail,
  createLeadFail,
  createLeadSuccess,
  updateLeadSuccess,
  updateLeadFail,
  deleteLeadSuccess,
  deleteLeadFail,
} from "./actions"

import { get, post, ApiPut, del } from "helpers/api_methods"
import { Notification } from "../../components/Common/Notification"
import { showToasterAction } from "store/actions"

// Lead - This line cannot be edited or removed
function getLeadsAPi({ page, sort, limit, searchText }) {
  return get(
    `/lead/admin/all/?sort=${sort}&page=${page ? page : 1}&limit=${
      limit ? limit : 10
    }&search=${searchText}`
  )
}

const getLeadDetailsAPi = leadId => {
  return get(`/lead/admin/single/${leadId}`)
}

const createLeadApi = ({ lead }) => {
  return post("/lead/user/new", lead)
}

const updateLeadApi = ({ lead, leadId }) => {
  return ApiPut(`/lead/admin/${leadId}`, lead)
}

const deleteLeadApi = ({ leadId }) => {
  return del(`/lead/admin/${leadId}`)
}

function* fetchLeads({ payload }) {
  try {
    const response = yield call(getLeadsAPi, payload)
    yield put(getLeadsSuccess(response))
  } catch (error) {
    yield put(getLeadsFail(error))
  }
}

function* fetchLeadDetails({ payload: leadId }) {
  try {
    const response = yield call(getLeadDetailsAPi, leadId)
    yield put(getLeadDetailsSuccess(response))
  } catch (error) {
    yield put(getLeadDetailsFail(error))
  }
}

function* onCreateLead({ payload }) {
  try {
    const response = yield call(createLeadApi, payload)
    if (response?.message) {
      Notification({
        type: "error",
        message: response?.message,
        title: "",
      })
    } else if (response) {
      yield put(createLeadSuccess(response))
      payload.dispatch(
        showToasterAction({
          type: "success",
          data: {
            title: "Lead",
            message: "Submitted Successfully!",
          },
        })
      )
    }
  } catch (error) {
    if (error?.response?.data?.message) {
      Notification({
        type: "error",
        message: error?.response?.data?.message,
        title: "",
      })
    }
    if (error?.response?.status === 413) {
      Notification({
        type: "error",
        message: "file size is Too Large",
        title: "",
      })
    }
    yield put(createLeadFail(error))
  }
}

function* onUpdateLead({ payload }) {
  try {
    const response = yield call(updateLeadApi, payload)
    if (response) {
      Notification({
        type: "success",
        message: "Lead Updated Successfully!",
        title: "",
      })
      yield put(updateLeadSuccess(response))
      if (payload.history) {
        payload.history.push("/leads")
      }
    }
  } catch (error) {
    if (error.response?.data?.message) {
      Notification({
        type: "error",
        message: error.response?.data?.message,
        title: "",
      })
    }
    if (error?.response?.status === 413) {
      Notification({
        type: "error",
        message: "file size is Too Large",
        title: "",
      })
    }
    yield put(updateLeadFail(error.response.data))
  }
}

function* onDeleteLead({ payload }) {
  try {
    const response = yield call(deleteLeadApi, LeadId)
    yield put(deleteLeadSuccess(response))
    Notification({
      type: "success",
      message: "Lead Deleted Successfully!",
      title: "",
    })
  } catch (error) {
    if (error?.response?.data?.message) {
      Notification({
        type: "error",
        message: error?.response?.data?.message,
        title: "",
      })
    }
    yield put(deleteLeadFail(error?.response?.data))
  }
}

function* leadSaga() {
  yield takeEvery(GET_LEADS, fetchLeads)
  yield takeEvery(GET_LEAD_DETAILS, fetchLeadDetails)
  yield takeEvery(CREATE_LEAD, onCreateLead)
  yield takeEvery(UPDATE_LEAD, onUpdateLead)
  yield takeEvery(DELETE_LEAD, onDeleteLead)
}

export default leadSaga

function doneNotification(message) {
  Notification({
    type: "success",
    message: message ? message : "done",
    title: "",
  })
}
