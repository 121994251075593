import { call, put, takeEvery } from "redux-saga/effects"

import {
  GET_LAYOUTPAGES,
  GET_PAGES,
  GET_PAGES_HOME,
  GET_SINGLE_PAGE,
} from "./actionTypes"
import {
  getLayoutPagesSuccess,
  getLayoutPagesFail,
  getPagesHomeSuccess,
  getPagesHomeFail,
  getPagesSuccess,
  getPagesFail,
  getSinglePageSuccess,
  getSinglePageFail,
} from "./actions"

import { get } from "helpers/api_methods"

function getLayoutPagesApi({ name }) {
  return get(`/growpage/user/single/name/${name}`)
}

function getPagesHomeApi({ defaultPage }) {
  return get(`/growpage/user/${defaultPage || "about"}`)
}

function fetchPagesAPI() {
  return get(`/groweb/user`)
}

function fetchSinglePagesApi(pageId) {
  return get(`/page/user/single/${pageId}`)
}

function* fetchLayoutPages({ payload }) {
  try {
    const response = yield call(getLayoutPagesApi, payload)
    yield put(getLayoutPagesSuccess(response))
  } catch (error) {
    yield put(getLayoutPagesFail(error))
  }
}
function* fetchPagesHome({ payload }) {
  try {
    const response = yield call(getPagesHomeApi, payload)
    yield put(getPagesHomeSuccess(response))
  } catch (error) {
    yield put(getPagesHomeFail(error))
  }
}

function* fetchPages({ payload }) {
  try {
    const response = yield call(fetchPagesAPI, payload)
    yield put(getPagesSuccess(response))
  } catch (error) {
    yield put(getPagesFail(error))
  }
}

function* fetchSinglePages({ payload }) {
  try {
    const response = yield call(fetchSinglePagesApi, payload)
    yield put(getSinglePageSuccess(response))
  } catch (error) {
    yield put(getSinglePageFail(error))
  }
}

function* LayoutPagesSaga() {
  yield takeEvery(GET_LAYOUTPAGES, fetchLayoutPages)
  yield takeEvery(GET_PAGES_HOME, fetchPagesHome)
  yield takeEvery(GET_PAGES, fetchPages)
  yield takeEvery(GET_SINGLE_PAGE, fetchSinglePages)
}

export default LayoutPagesSaga
